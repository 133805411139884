import { createApp } from 'vue'
import App from './App.vue'

import router from "./router"; // Used to route pages for navigation
// import * as THREE from 'three'; // The base of all the 3D
// require("aframe-orbit-controls"); // Might be needed to place move and rotate items on page


const app = createApp(App);
app.config.ignoredElements = [/^a-/]
// app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('a-');
app.use(router);
app.mount('#app');