<template>
  <div class="footer">
    <div class="footer__wrapper">
      <div class="footer___menubg">
        <div id="globallink1" :onClick="show_ISI"><p style="text-align: center;">Lexiscan Important Safety Information</p></div>
        <div id="globallink2" :onClick="getExternalLink"><p>Lexiscan Prescribing Information</p></div>
      </div>
    </div>

    <div class="popup__container"  id="popupISI" v-if="!popupHidden">
      <div class="popup__header">
        <div :onClick="show_ISI"><img src="~@/assets/images/icons/close-icon.svg" alt="x_button" id="close_ISI" > </div>
        <img src="~@/assets/images/icons/lexiscan-logo.svg" alt="lexiscan_graphic" id="lexiscan_logo">       
      </div>
      <div class="scrollMessage" id="scroll_to_read" ref="popScroll">
        <div id="scroll_icon"></div>
        <p>Scroll to read.</p>
      </div>
      <div class="popup__text activate">
        <h2>INDICATION AND IMPORTANT SAFETY INFORMATION</h2><p></p>
        <h2>INDICATION</h2><p></p>
        <h4>Lexiscan<span class="super">®</span> (regadenoson) injection is a pharmacologic stress agent indicated for radionuclide myocardial perfusion imaging (MPI) in patients unable to undergo adequate exercise stress.</h4><p></p>
        <h2>IMPORTANT SAFETY INFORMATION</h2><p></p>
        <h2>CONTRAINDICATIONS</h2>
        <h4>Do not administer Lexiscan to patients with second- or third-degree AV block or sinus node dysfunction unless these patients have a functioning artificial pacemaker.</h4><p></p>
        <h2>WARNINGS AND PRECAUTIONS</h2>
        <h3>Myocardial Ischemia</h3>
        <h4>Fatal and nonfatal myocardial infarction, ventricular arrhythmias, and cardiac arrest have occurred following Lexiscan injection. Avoid use in patients with symptoms or signs of acute myocardial ischemia, for example unstable 
          angina or cardiovascular instability; these patients may be at greater risk of serious cardiovascular reactions to Lexiscan. Cardiac resuscitation equipment and trained 
          staff should be available before administering Lexiscan. Adhere to the recommended duration of injection. As noted in an animal study, longer injection times may increase 
          the duration and magnitude of increase in coronary blood flow. If serious reactions to Lexiscan occur, consider the use of aminophylline, an adenosine antagonist, to shorten 
          the duration of increased coronary blood flow induced by Lexiscan.</h4>
        <h3>Sinoatrial and Atrioventricular Nodal Block</h3>
        <h4>Adenosine receptor agonists, including Lexiscan, can depress the SA and AV nodes and may cause first-, second-, or third-degree AV block, or sinus bradycardia requiring intervention. In postmarketing experience, heart block 
          (including third degree), and asystole within minutes of Lexiscan administration have occurred.</h4>
        <h3>Atrial Fibrillation/Atrial Flutter</h3>
        <h4>New-onset or recurrent atrial fibrillation with rapid ventricular response and atrial flutter have been reported following Lexiscan injection.</h4>
        <h3>Hypersensitivity, Including Anaphylaxis</h3>
        <h4>Anaphylaxis, angioedema, cardiac or respiratory arrest, respiratory distress, decreased oxygen saturation, hypotension, throat tightness, urticaria and rashes have occurred. In clinical trials, hypersensitivity reactions were 
          reported in fewer than 1 percent of patients.</h4>
        <h3>Hypotension</h3>
        <h4>Adenosine receptor agonists, including Lexiscan, induce arterial vasodilation and hypotension. The risk of serious hypotension may be higher in patients with autonomic dysfunction, hypovolemia, left main coronary artery stenosis, 
          stenotic valvular heart disease, pericarditis or pericardial effusions, or stenotic carotid artery disease with cerebrovascular insufficiency. In postmarketing experience, transient ischemic attacks, seizures and syncope have been observed.</h4>
        <h3>Hypertension</h3>
        <h4>Adenosine receptor agonists, including Lexiscan, may result in clinically significant increases in blood pressure in some patients. In postmarketing experience, cases of potentially clinically significant hypertension have been reported, 
          particularly in patients with underlying hypertension and when low-level exercise was included in the MPI.</h4>
        <h3>Bronchoconstriction</h3>
        <h4>Adenosine receptor agonists, including Lexiscan, may cause dyspnea, bronchoconstriction and respiratory compromise. Appropriate bronchodilator therapy and resuscitative measures should be available prior to and following Lexiscan administration.</h4>
        <h3>Seizure</h3>
        <h4>Lexiscan may lower the seizure threshold; obtain a seizure history. New-onset or recurrence of convulsive seizures has occurred following Lexiscan injection. Some seizures are prolonged and require emergent anticonvulsive management. 
          Aminophylline may increase the risk of seizures associated with Lexiscan injection. Methylxanthine use is not recommended in patients who experience a seizure in association with Lexiscan administration.</h4>
        <h3>Cerebrovascular Accident (Stroke)</h3>
        <h4>Hemorrhagic and ischemic cerebrovascular accidents have occurred. Hemodynamic effects of Lexiscan including hypotension or hypertension may be associated with these adverse reactions.</h4><p></p>
        <h2>ADVERSE REACTIONS</h2><p></p>
        <h4>In clinical trials, the most common adverse reactions (≥5%) to Lexiscan were dyspnea, headache, flushing, chest discomfort, angina pectoris or ST-segment depression, dizziness, chest pain, nausea, abdominal discomfort, dysgeusia, and feeling hot. 
          Most adverse reactions began soon after dosing, and generally resolved within approximately 15 minutes, except for headache, which resolved in most patients within 30 minutes. Aminophylline was used as a reversal agent in 3% of patients.</h4>
        <h4>In postmarketing experience, the following additional adverse reactions have occurred: supraventricular tachyarrhythmias, acute coronary syndrome (ACS), tremor, QTc prolongation, abdominal pain in association with nausea, vomiting, or myalgias, 
          diarrhea, fecal incontinence, wheezing and musculoskeletal pain.</h4>
        <h5><p :onClick="getExternalLink">PLEASE SEE FULL PRESCRIBING INFORMATION FOR LEXISCAN.</p></h5><p></p>
      </div>
    </div>
  </div>
</template>

<script>

import store from "../Store";
import { gsap } from "gsap";
// import {pauseVO} from '@/lib/audio_clips.js';
export default {
  name: "Footer",
  components: {
  },
  data: function() {
    return {
      popupHidden: true
    };
  },
  methods: {
    getExternalLink() {
      window.open(
      "https://www.astellas.us/docs/lexiscan.pdf?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=see-full-prescribing-information&utm_term=na", "_blank");
    },
    show_ISI() {
      try {
        // pauseVO();
        if(this.popupHidden){
          document.getElementById('app_header').style.visibility = 'hidden'; 
          window.addEventListener("touchmove", this.fadeOnScroll);}
        else{
          document.getElementById('app_header').style.visibility = 'visible';
        }
        store.showHeader = !this.popupHidden;
        this.popupHidden = !this.popupHidden;
        console.log("ISI pressed");
       } catch (e) {
         console.log("ERROR", e);
       }
    },
    fadeOnScroll: function () {
      //console.log("scrolled");
      let tl = gsap.timeline(); 
      tl.to(this.$refs.popScroll, { delay: 0, duration: 1, autoAlpha: 0 });
      window.removeEventListener("touchmove", this.fadeOnScroll);      
    },
  },
  mounted: function() {},
  unmounted: function() {},
};
</script>

<style scoped lang="scss">
.footer {
  align-items: center;
  position: absolute;
  bottom: 0px;
  visibility: visible;
  width: 100%;
  //height: 80px;
  z-index: 100;

    .activate {
    pointer-events: auto;
    }


  &__wrapper {
    //display: flex;
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 100%;
  }
  &___menubg {
    position: absolute;
    width: 100%;
    height: 70px;
    background-color: $white;
    align-content: center;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.5);
  }
  &___copy {
    position: absolute;
    line-height: 18px;
    font-size: 20px;
    color: #fff;
    z-index: 1;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    bottom: 0px;
    text-shadow: 0px 0px 5px #00000070;
  }
}

  #globallink1 {
    position: fixed;
    width: 49.5%;
    height: 70px;
    bottom: 0;
    color: $purpleLight;
    text-align: center;
    padding: 22px 10px 10px 10px;
    font-family: "HelveticaNeueMdCn" !important;;
    font-size: 16px;
    //font-weight: bold;
    letter-spacing: 0.2px;
    background-color: $whiteDark;
    pointer-events:auto;
  }

  #globallink2 {
    position: fixed;
    right: 0;
    width: 49.5%;
    height: 70px;
    bottom: 0;
    color: $purpleLight;
    text-align: center;
    padding: 22px 10px 10px 10px;
    font-family: "HelveticaNeueMdCn" !important;;
    font-size: 16px;
    //font-weight: bold;
    letter-spacing: 0.2px;
    background-color: $whiteDark;
    pointer-events:auto;
  }

.popup {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;

  &__container {
    //position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 94vw;
    height: 72vh;
   // bottom: 15%;
    transform: translate(0%, -20%);
    -ms-transform: translate( 0%, -20%);
   // padding: 10px;
  }

  &__header {
    color: $purple;
    font-weight: $weight-bold;
    font-size: $size-large;
    position: relative;
    width: 100%;
    height:120px;
    background-color: $white;
    z-index: 8;
    top: 0;
    text-align: center;
    padding: 20px;
  }

  &__text {
    color: $purple;
    text-align: left;
    align-items: left;
    padding-left:25px;
    padding-right:25px;
    padding-bottom:50px;
    flex-grow: 1;
    overflow-x: auto;
    pointer-events:auto;
    width: 96%;
    z-index: 7;
  }
}


  // .popup__text::-webkit-scrollbar {
  // width: 15px;
  // color: $purple;
  // }

  // .popup__text::-webkit-scrollbar-track {
  // background-color: $purple;
  // border: 5px solid transparent;
  // background-clip: content-box;
  // }

  // .popup__text::-webkit-scrollbar-thumb {
  //   background-color: $purple;
  //   border-radius: 15px;  
  // }

  .screenContainer{
  width: 100vw;
  height: 100vh;
  position: absolute;
  align-items: center;
}

#lexiscan_logo {
  width: 75%;
  float: left;
}

#close_ISI {
  width: 42px;
  height: 42px;
  float: right;
  z-index: 110;
  pointer-events: auto;
}

.scrollMessage {
  width: 100%;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 30%;
  z-index: 29;
    
  p {
    width: 80%;
    margin: 0 auto;    
    color: white;
    padding-top:45px;
    padding-left:40px;
    padding-right:40px;
    padding-bottom:45px;
    font-family: "HelveticaNeueCn";
    font-size: 22px;
    font-weight: bold;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 31;
  }
}

 #scroll_icon {
  width: 45pt;
  height: 45pt;
  z-index: 30;
  position: relative;
  //left: 50%;
  margin-left: auto;
  margin-right: auto; 
  border-radius: 50%;
  //padding: 18px;
  background-color: $purple;
  background-image: url("~@/assets/images/icons/scroll-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  transform: translate(0%, 30%);
  -ms-transform: translate( 0%, 30%);
}

.super{
  vertical-align: super;
  font-size: 12px;
 }
</style>