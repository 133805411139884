<template>
  <div id="containerP" ref="particle"></div>
</template>

<script>
import { gsap, Sine, Linear } from "gsap";
export default {
  name: "Particles",
  data: function() {
    return {
      scene: null,
      total: 200,
      w: 0,
      h: 0, 
      speed: 5
    };
  },
  methods: {
    animm(elm) {
      gsap.to(elm, this.Random(this.speed) + 4, {
        y: this.h,
        ease: Linear.easeNone,
        repeat: -1,
        delay: -5,
      });
      gsap.to(elm, this.Random(this.speed) + 1, {
        x: "+=70",
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut,
      });
      gsap.to(elm, this.Random(this.speed) + 1, {
        scaleX: 0.2,
        rotation: this.Random(360),
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut,
      });
      gsap.to(elm, this.Random(1) + 0.5, {
        opacity: 0,
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut,
      });
    },
    Random(max) {
      return Math.random() * max;
    },
    random(min, max) {
      return min + Math.floor(Math.random() * (max - min));
    }
  },
  mounted: function() {
    // gsap.set("img", { xPercent: "-50%", yPercent: "-50%" });
    this.w = this.$refs.particle.clientWidth;
    this.h = this.$refs.particle.clientHeight;
    window.particles = this.$refs.particle;
    for (let i = 0; i < this.total; i++) {
      let dot = document.createElement("div");
      dot.classList.add("dot");
      this.$refs.particle.append(dot);
      let divArr = document.querySelectorAll(".dot");
      gsap.set(divArr[i], {
        x: this.Random(this.w),
        y: this.random(-300, 100),
        width: 10,
        height: 16,
        opacity: 1,
        scale: this.Random(0.5) + 0.5,
        backgroundColor: "hsl(" + this.random(170, 360) + ",100%,50%)",
      });
      this.animm(divArr[i]);
    }
  },
};
</script>
<style scoped lang="scss">

#containerP {
  width: 100%;
  height: 100%;
  z-index: 2000;

}
  #containerP.dot {
  width: 7px;
  height: 12px;
  position: absolute;
  background-color: #00ff00;
  z-index: 2;
}
</style>
