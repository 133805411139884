<template>
  <div class="landscape pagefull">
      <div class="landscape__hint">
        <img
          :src="require('@/assets/images/orientation/rotate.png')"
          :srcset="require('@/assets/images/orientation/rotate@2x.png') + ' 2x'"
          alt="Allow download"
        />
        <p class="text">Please reposition your device</p>
      </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.landscape {
  width:100vw;
  background-color: #00000033;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 20000;
  &__hint {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .text {
    color: #fff;
    background-color: $black20;
  }
}
</style>