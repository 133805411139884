
var store = {
  debug: false,
  state: {
    landscape: false,
    selected: null,
    selectedobject: null,
    showHeader: true,
    showExit: false,
    arReady: false,
    modelLoaded: false,
    placedModel: false
  },
  set landscape(value) {
    if (this.debug) console.log('store.landscape action triggered', value)
    this.state.landscape = value;
  },
  set selected(value) {
    if (this.debug) console.log('store.selected action triggered', value)
    this.state.selected = value;
  },
  set selectedobject(value) {
    if (this.debug) console.log('store.selectedobject action triggered', value)
    this.state.selectedobject = value;
  },
  set showHeader(value) {
    if (this.debug) console.log('store.showHeader action triggered', value)
    this.state.showHeader = value;
  },
  set showExit(value) {
    if (this.debug) console.log('store.showExit action triggered', value)
    this.state.showExit = value;
  },
  set arReady(value) {
    if (this.debug) console.log('store.arReady action triggered', value)
    this.state.arReady = value;
  },
  set modelLoaded(value) {
    if (this.debug) console.log('store.modelLoaded action triggered', value)
    this.state.modelLoaded = value;
  },
  set modelplacedModelLoaded(value) {
    if (this.debug) console.log('store.placedModel action triggered', value)
    this.state.placedModel = value;
  }
}

window.store = store;
export default store;