<template>
  <div class="exitPage">
      <div class="screenContainer" id="exitWindow"  style="visibility: visible">
        
        <div class="exitW"><div class="backdrop"></div>
            <div class="exitW___title">Are you sure you want to leave?</div><p></p>
            <p class="stay activate" :onClick="chooseStay">I want to stay.</p>
            <p class="stay activate" :onClick="chooseExit">I want to visit Lexiscan.com</p>
        </div>
     </div> 
  </div>
</template>

<script>

//import router from "../router/index";
//import store from "../Store";
export default {
 // name: "Exit",
  data: function() {
    return {
 //     state: store.state,
    };
  },
  components: {},
  mounted() {
    // Run Code once code is mounted
  },
  // watch: {
  //   'store.state.showExit': function() {
  //     console.log(this.$store.state.showExit)
  //   }
  // },
  methods: {
    reload() {
      // Go Back to main Page
    },
    chooseExit() {
      window.open("https://www.lexiscan.com/?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=lexiscan&utm_term=na","_self");
    },
    chooseStay() {
    // store.showExit(false);
    }
  },
};
</script>
<style lang="scss" scoped>
.exitPage {
  position: absolute;
  width: 100%;
  height: 100%;
  //display: flex;
  //justify-content: space-between;
  pointer-events: none;
  z-index: 1000;

  .activate {
    pointer-events: auto;
    }
}
.screenContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  
  align-items: center;

}

.exitW {
    align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    border-radius: 25px;
    background-color: $grey;
    color: $white;
    opacity: 0.8;
    text-align: center;
    padding: 20px;
    font-family: "HelveticaNeue";
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.6px;
    z-index: 45;
    
  &__title {
    width: 80%;
    margin-top: 1.5;
    margin-left: auto;
    margin-right: auto;   
    color: $white;
    padding: 20px;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.6px;

  }

   .stay {
    width: 80%;
    margin-top: 1.5;
    margin-left: auto;
    margin-right: auto;   
    color: $purpleLight;
    padding: 20px;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.6px;
    border-radius: 15px;
    border: 2px solid white; 
  }

}

.backdrop{
  width: 100%;
  height: 110%;
  top: 0;
  backdrop-filter: blur(10px) brightness(0.8);
  z-index:40;
}
</style>
