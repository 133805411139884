var clip1 = new Audio();
var clip2 = new Audio({volume: .2});
var clip3 = new Audio();

export var voArray = ["./assets/vo_clips/VO_01.mp3", "./assets/vo_clips/VO_02.mp3", "./assets/vo_clips/VO_03.mp3", "./assets/vo_clips/VO_04.mp3", "./assets/vo_clips/VO_05.mp3", "./assets/vo_clips/VO_06.mp3", "./assets/vo_clips/VO_07.mp3", "./assets/vo_clips/VO_08.mp3", "./assets/vo_clips/VO_09.mp3", "./assets/vo_clips/VO_10.mp3", "./assets/vo_clips/VO_11.mp3", "./assets/vo_clips/VO_12.mp3", "./assets/vo_clips/VO_13.mp3", "./assets/vo_clips/VO_14.mp3", "./assets/vo_clips/VO_15.mp3", "./assets/audioFX/nothing.mp3"];
clip1.addEventListener('ended', function(e) {
  console.log('ended>>>>>>>', e);
  console.log('ended>>>>>>>', e.currentTarget.callback);
  if(e.currentTarget.callback) {
    console.log('Call Back')
    e.currentTarget.callback();
  }
  
});
export var currentTrack = 0;
export function playVO(clipNumber, obj = null) {
  clip1.pause();
  clip1.currentTime = 0;
  clip1.src = voArray[clipNumber];
  clip1.load();
  clip1.play();
  clip1.callback = obj && obj.callback ? obj.callback : null;
  console.log(clipNumber, 'AUDIO  CLIP', voArray[clipNumber], clip1, clip1.callback);
  currentTrack = clipNumber;
}

// I am putting sfx in different function and array because I figure er will not want the VOs to be interrupted based on user input.
export var sfxArray = ["./assets/audioFX/Temp/SFX_Congrats.m4a", "./assets/audioFX/Temp/SFX_CongratsAlt.m4a", "./assets/audioFX/Temp/SFX_CorrectAnswer.m4a", "./assets/audioFX/Temp/SFX_Start.m4a", "./assets/audioFX/Temp/SFX_StartAlt.m4a", "./assets/audioFX/Temp/SFX_WrongAnswer.m4a"];
clip2.addEventListener('ended', function(e) {
  console.log('ended>>>>>>>', e);
  console.log('ended>>>>>>>', e.currentTarget.callback);
  if(e.currentTarget.callback) {
    console.log('Call Back')
    e.currentTarget.callback();
  }
  
});

export var sfxTrack = 0;
export function playSFX(sfxNumber, obj = null) {
  clip2.pause();
  clip2.currentTime = 0;
  clip2.src = sfxArray[sfxNumber];
  clip2.load();
  clip2.play();
  clip2.callback = obj && obj.callback ? obj.callback : null;
  console.log(sfxNumber, 'AUDIO  CLIP', sfxArray[sfxNumber], clip2, clip2.callback);
  sfxTrack = sfxNumber;
}

export var uiArray = ["./assets/audioFX/nothing.mp3", "./assets/audioFX/ui04.mp3"];
clip3.addEventListener('ended', function(e) {
  console.log('ended>>>>>>>', e);
  console.log('ended>>>>>>>', e.currentTarget.callback);
  if(e.currentTarget.callback) {
    console.log('Call Back')
    e.currentTarget.callback();
  }
  
});

export var uiTrack = 0;
export function playUI(uiNumber, obj = null) {
  clip3.pause();
  clip3.currentTime = 0;
  clip3.src = uiArray[uiNumber];
  clip3.load();
  clip3.play();
  clip3.callback = obj && obj.callback ? obj.callback : null;
  console.log(uiNumber, 'AUDIO  CLIP', uiArray[uiNumber], clip3, clip3.callback);
  uiTrack = uiNumber;
}
export function pauseVO() {

 if(!clip1.paused && currentTrack !== null)
 { 
  clip1.pause();
 }else if (!clip1.ended){
  clip1.play();
 }
}
