
import store from "../Store";
function animateModel(model, tlObj, type, prop, end) {

  let tempModel = model.getObject3D('mesh');
  // console.log('1  tempModel   ', tempModel, model);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh) {
      // console.log('prop   ', prop);
      tlObj[type](node["material"], prop, end);
    }
  });
}
function changeImage(model, tlObj, type, prop, end) {
  let tempModel = model.getObject3D('mesh');
  // console.log('1  tempModel   ', tempModel, model);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh && node.material.name === 'board') {
      // console.log('prop   ', prop);
      tlObj[type](node["material"], prop, end);
    }
  });
}


function modelFix(model) {
  console.log('model  ', model)
  let tempModel = model.getObject3D('mesh');
  // console.log('2  tempModel   ', tempModel);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh) {
      // node.geometry.computeBoundingBox();
      node.material.transparent = true;
      node.frustumCulled = false;
      // node.geometry.boundingBox.expandByScalar(2);
    }
  });
}

function resetAnimation() {
  const tony = document.getElementById("model0");
  tony.removeAttribute("gltf-model");
  tony.setAttribute("gltf-model", './assets/models/hoop.gltf');
  tony.setAttribute("animation-mixer", { timeScale: 0 });
}

var soundEvents = [];
function resetAudio() {
  // Select the div in the window
  const bg = document.getElementById("bgSound");

  // Sound files to load 
  const bgAudio = '';

  // Remove Audio Event
  if(soundEvents.length > 0){
    // Stop Audio
    bg.components.sound.stopSound();
  }

  // Set Audio object to empty
  bg.setAttribute("sound", {src: `url(${bgAudio})`, autoplay:false, volume: 0});

  // Clear Array
  soundEvents = []
}
function loopThroughAll(obj, callfunc){
  for (let c = 0; c < obj.children.length; c++) {
    let subObj = obj.children[c];
    callfunc(subObj)
    if(subObj.children.length > 0){
      loopThroughAll(subObj, callfunc);
    }
  }
}
function setAllObjectParams(modelNames) {
  // for (let i = 0; i < modelNames.length; i++) {
    let obj = window.scene.getObjectByName(modelNames);
    loopThroughAll(obj, setProperties)
    setProperties(obj)
  // }
}
function setProperties(obj){
  // obj.castShadow = true;
  // obj.receiveShadow = true;
  // obj.visible = true;
  // obj.material.side = THREE.DoubleSide;
  if(obj.material && obj.material.opacity){

    // obj.material.transparent = true;
    // obj.material.opacity = 1;
  }
}
function setColorOverlay(obj){
  if(!obj || !obj.material || !obj.material.emissive) {return;}

  if ( obj ) obj.material.emissive.setHex( obj.currentHex );
  obj.currentHex = obj.material.emissive.getHex();
  obj.material.emissive.setHex( 0xff0000 );
}
function selectedObject(modelNames){
  console.log('modelNames   ', modelNames);
  let obj = window.scene.getObjectByName(modelNames);
  loopThroughAll(obj, setColorOverlay);
}
let outlineArr = [];
function addOutline(objArr){
  outlineArr = [];
  for (let c = 0; c < objArr.length; c++) {
    console.log('modelNames   ', objArr, objArr[c]);
    // let obj = window.scene.getObjectByName(modelNames);
    loopThroughAll(objArr[c], addMeshToArray);
  }
  console.log( 'Added to outline >>>>>>>', outlineArr );
  window.outlineArr = outlineArr;
  window.outlineObjects(outlineArr);
}
function addMeshToArray(obj){
  console.log('obj, ', obj.isMesh, obj);
  if(!obj || !obj.isMesh) {return;}
  outlineArr.push(obj);
  
  
}
function setAudio(skip = false) {
  const selected = store.state.selected;
  console.log('selected    ', selected);
  const bg = document.getElementById("bgSound");
  
  let aFile = selected.sound.bg;
  let aLoop = selected.sound.loop;
  let aVolume = selected.sound.volume;
  console.log(skip, 'setAudio    ', selected.sound);
  if(('talk' in selected.sound) && !skip){
    console.log('CHECK IF AM IN     ');
    console.log('CHECK IF AM IN     ', ('talk' in selected.sound));
    aFile = selected.sound.talk;
    aLoop = false;
  }
  console.log('OBJECT CHECK     ', ('talk' in selected.sound));
  bg.setAttribute("sound", {src: `url(${aFile})`, autoplay:false, loop:aLoop, volume: aVolume});
}


function playPauseSceneAudio(pause = false) {
  const bg = document.getElementById("bgSound");
  if(pause){
    bg.components.sound.pauseSound();
  } else {
    bg.components.sound.playSound();
  }
  
}
function playCLickSound() {
  const clickSnd = document.getElementById("clickSnd");
  clickSnd.play();
  
}
// function modelFixScene(model) {
//   let tempModel = model.getObject3D('mesh');
//   console.log('2  tempModel   ', tempModel);
//   if (tempModel === undefined) {
//     return;
//   }
//   scene.traverse(function (object) {

//     object.frustumCulled = false;

//   })
// }
function RAND(min, max, decimalPlaces = 5) {
  // return Math.floor(Math.random() * (max + 1)) + min;
  var rand = Math.random()*(max-min) + min;
  var power = Math.pow(10, decimalPlaces);
  return Math.floor(rand*power) / power;
}

function MobileVer() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log('useragent', userAgent)
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function externalLink() {
  let url = '';

  return url;
}
function addAudio(file){
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  const audio = new Audio(file);
  const source = audioCtx.createMediaElementSource(audio);
  source.connect(audioCtx.destination);
  return source;
}
function Interpolate(start, end, steps, count) {
  var s = start,
    e = end,
    final = s + (((e - s) / steps) * count);
  return Math.floor(final);
}
function convertColorToPoint(uNum) {
  return (100 / 360 * .01) * uNum;
}
function Color(_r, _g, _b) {
  var r, g, b;
  var setColors = function (_r, _g, _b) {
    r = _r;
    g = _g;
    b = _b;
  };

  setColors(_r, _g, _b);
  this.getColors = function () {
    var colors = {
      r: r,
      g: g,
      b: b
    };
    return colors;
  };
}

export {
  animateModel,
  modelFix,
  resetAnimation,
  changeImage,
  setAllObjectParams,
  selectedObject,
  RAND,
  MobileVer,
  Interpolate,
  Color,
  convertColorToPoint,
  externalLink,
  addAudio,
  resetAudio,
  setAudio,
  playPauseSceneAudio,
  playCLickSound,
  addOutline
}