import { createRouter, createWebHashHistory } from 'vue-router';
import WebAR from '../pages/WebAR.vue';
import Home from '../pages/Home.vue';
import Error from '../pages/Error.vue';
import Exit from '../pages/Exit.vue';
import PermissionError from '../pages/PermissionError.vue';
import PlaneDetection from '../pages/PlaneDetection.vue';
import Intro from '../pages/Intro.vue';
import Challenge1 from '../pages/Challenge1.vue';
import Final from '../pages/Final.vue';
import Desktop from '../pages/Desktop.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props:true
  },
  {
    path: '/webar',
    name: 'WebAR',
    component: WebAR,
    children: [
       {
      //   // UserProfile will be rendered inside User's <router-view>
      //   // when /user/:id/profile is matched
         path: '',
         component: WebAR
       },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'start',
        component: PlaneDetection
      },
      {
        path: 'intro',
        component: Intro
      },
      {
        path: 'challenge1',
        component: Challenge1
      },
      {
        path: 'exit',
        name: 'Exit',
        component: Exit,
      },
      {
        path: 'final',
        name: 'Final',
        component: Final,
      }

    ]
  },
  {
    path: '/permission-error',
    name: 'PermissionError',
    component: PermissionError,
  },
  // {
  //   path: '/final',
  //   name: 'Final',
  //   component: Final,
  // },
  {
    path: '/error',
    name: 'Error',
    component: Error,
  },
  {
    path: '/desktop',
    name: 'Desktop',
    component: Desktop,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
