<template>
  <div class="Header" id="app_header">
    <div class="Header___bg"></div>
    <div class="Header___CTA" >
      <image
        id="resetButton"
        class="reset activate"
        :src="require('@/assets/images/icons/reset-icon.svg')"
        alt="1"
        :onClick="reCenter"
      />
      <image
        class="close activate"
        :src="require('@/assets/images/icons/close-icon.svg')"
        alt="1"
        :onClick="close"
      />

      <div class="screenContainer" id="exitWindow" style="visibility: hidden">
      <div class="backdrop">
      <div class="exitW">
          <div class="exitW___title">Are you sure you want to leave?</div><p></p>
          <p class="stay activate" :onClick="close">I want to stay.</p>
          <p class="stay activate" :onClick="chooseExit">I want to visit Lexiscan.com.</p>
      </div></div>
     </div>

    </div>

    <v-exit />

  </div>

</template>

<script>
import store from "../Store";
import Exit from "@/pages/Exit";
import {pauseVO} from '@/lib/audio_clips.js';
export default {
  name: "Header",
  data: function() {
    return {
      scene: null,
      showButton: false,
      state: store.state,
      "v-exit": Exit,
      popHidden: true,
    };
  },
  methods: {
    reCenter: function() {
      try {
        console.log("Window  ", window);
        console.log("this.scene  ", this.scene);
        // window.Geenee.activeSceneModel.startSlamExperience(window.innerWidth / 2, window.innerHeight-200);
        console.log("RECENTERED CALLED  ");
        // console.log("this.el.sceneEl  ", this.el.sceneEl);
      } catch (e) {
        console.log("ERROR", e);
      }
    },
    close() {
        try {
         pauseVO();
        if(!this.popHidden){
          document.getElementById('exitWindow').style.visibility = 'hidden';
          }
        else{
          document.getElementById('exitWindow').style.visibility = 'visible';
        }
        store.showExit = this.popHidden;
        this.popHidden = !this.popHidden;
        console.log("Close pressed");
       } catch (e) {
         console.log("ERROR", e);
       }
    },
    chooseExit() {
      window.open("https://www.lexiscan.com/?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=lexiscan&utm_term=na","_self");
    },

  },
  beforeMount: function() {},
  watch: {
    $route() {
      this.showButton = this.$route.path.indexOf("start") !== -1 ? false : true;
    },
    'store.state.showHeader': function() {
      console.log(this.$store.state.showHeader)
    }
  },
  mounted: function() {
    this.scene = document.getElementById("scene");
  },
};
</script>
<style scoped lang="scss">
.Header {
  position: absolute;
  width: 100%;
  // padding: 20px;
  //display: flex;
  //justify-content: space-between;
  pointer-events: none;
  z-index: 1000;
  > * {
    z-index: 2;
  }
  &___bg {
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: transparent;
    content: "";
    // z-index: 0;
  }
  &___logo {
    position: relative;
    width: 177px;
    z-index: 2;
    margin: 10px;
    height: 105px;
  }
  &___CTA {
    float: center;
    position: relative;
    padding: 10px 10px 0 0;
    //text-align: right;


    .reset {
      float: left;
      width: 105px;
      height: 48px;
      margin-top: 1%;
      margin-left: 8%;
      border-radius: 8px;
      //padding: 5px;
      opacity: 1;
      background-image: url("~@/assets/images/icons/reset-icon.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
    }
    .close {
      float: right;
      width: 42px;
      height: 42px;
      margin-top: 2%;
      margin-right: 5%;
      border-radius: 50%;
      opacity: 1;
     // padding: 10px;
      background-image: url("~@/assets/images/icons/close-icon.svg");
      //background-color: $purpleLight;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
    }

  }
}
.activate {
  pointer-events: auto;
}
.photoNote {
  background: rgb(0 0 0 / 33%);
  border-radius: 6px;
  -webkit-backdrop-filter: blur(23px);
  backdrop-filter: blur(23px);
  font-size: 1.2rem;
  color: #fff;
  padding: 10px;
  opacity: 0;
}
.screenContainer{
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;

  align-items: center;

}

.exitW {
    align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 36%;
    bottom: 0;
    border-radius: 15px;
    background-color: $grey;
    color: $white;
    opacity: 0.8;
    text-align: center;
    padding: 30px 20px 18px 20px;
    font-family: "HelveticaNeue";
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.6px;
    transform: translate(0%, 70%);
    -ms-transform: translate( 0%, 70%);
    z-index: 45;

  &__title {
    width: 80%;
    margin-top: 1.5;
    margin-left: auto;
    margin-right: auto;
    color: $white;
    padding: 20px;
    font-family: "HelveticaNeue";
    font-weight: bold;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.6px;

  }

   .stay {
    width: 80%;
    margin-top: 1.5;
    margin-left: auto;
    margin-right: auto;
    color: $purpleLightest;
    padding: 20px;
    font-family: "HelveticaNeueCn";
    font-weight: bold;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.6px;
    border-radius: 15px;
    border: 2px solid white;
  }

}

.backdrop{
  width: 100vw;
  height: 110vh;
  //top: 0;
  backdrop-filter: blur(10px) brightness(0.8);
  z-index:40;
  display: flex;
}

</style>
