<template>
    <div class="permission-error-p">
        <div class="permission-error-p___text">I'm sorry. We need to access your camera to launch the Lexiscan Challenge. </div><br>
        <div>
        <p id="activate" :onClick="activateCamera">Activate my camera ››</p><br>
        <div class="permission-error-p___text">Or, <h8 :onClick="gotoLexiscan">click here</h8> to learn more about
          Lexiscan<span class="super">®</span> (regadenoson) injection or to request to speak to a Lexiscan Sales Representative.</div><br>
       <!-- <p id="leave" :onClick="gotoLexiscan">Lexiscan.com ››</p> -->
        </div>
       <!--<div id="policy" class="permission-error-p___text" :onClick="seePolicy">Privacy Policy</div> -->
    </div>
</template>


<script>
// import router from "../router/index";

// import router from "../router/index";
//  import Permissions from "@/lib/Permissions";
export default {
  components: {},
  mounted() {
    // Run Code once code is mounted
  },
  methods: {
    reload() {
      // Go Back to main Page
    },
    gotoLexiscan() {
      //console.log('gotoLexiscan');
      window.open("https://www.lexiscan.com/?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=lexiscan&utm_term=na","_self");
      // Exit to main site
    },
    activateCamera() {
      // Permissions.permissions();
      let url = `https://${window.location.hostname}${window.location.port != '' ? ":" + window.location.port : ''}?${Date.now()}`;

      console.log('url   ', url);
      window.open(url, "_blank");
    },
    seePolicy() {
      window.open(
      "https://www.astellas.com/en/privacy-policy?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=privacy-policy&utm_term=na", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>

.permission-error-p {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    text-align: center;  
    color: $grey;
    font-family: "HelveticaNeueCn";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    //letter-spacing: -0.6px;
    line-height: 120%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    

    &___text{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: $grey49; 
    }

  p {
    width: 75%;
    margin-left: auto;
    margin-right: auto;  
    color: white;
    padding: 21px 18px 18px 18px;
    font-family: "HelveticaNeueCn";
    font-style: normal;
    font-size: 22px;
    font-weight: bold;
   // letter-spacing: -0.5px;
    border-radius: 15px;
    background-color: $purple;
    border: 4px solid white; 
    }
  }
  #policy{
    width: 100%;
    position: absolute;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    bottom: 15px;
    font-family: "HelveticaNeueMdCn";
    font-size: 16px;
    color: $purple;
   // font-weight: bold;
    z-index: 15;
  }

  .super{
  vertical-align: super;
  font-size: 12px;
 }
</style>
