<template>
  <div class="wavebg">
    <img
      class="img2"
      ref="wave1"
      :src="require('@/assets/images/home/wave.png')"
      alt=""
    />
    <img
      class="img2"
      ref="wave2"
      :src="require('@/assets/images/home/wave.png')"
      alt=""
    />
  </div>
</template>

<script>
import { gsap } from "gsap";
import store from "../Store";
export default {
  name: "Footer",
  components: {},
  data: function() {
    return {
      state: store.state,
    };
  },
  methods: {
    onClickChild(value) {
      console.log(value); // someValue
    },
  },
  mounted: function() {
    gsap.set(this.$refs.wave1, { x:0})
    gsap.set(this.$refs.wave2, { x:1200})
    gsap.to(this.$refs.wave1, { duration: 10, x: -1494, repeat:-1, ease:"none"})
    gsap.to(this.$refs.wave2, { duration: 10, x: 0, repeat:-1, ease:"none"})
    // gsap.to(this.$refs.wave2, { duration: 10, scaleX: -2, scaleY:.5, repeat:-1, yoyo:true})
  },
  unmounted: function() {},
};
</script>

<style scoped lang="scss">
.wavebg {

  position: relative;
    top: -10%;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      position: absolute;
    }
    .img1 {
      
    }
    .img2 {
      
    }
}
</style>
