<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane" ref="mainWindow">
      <v-footer class="footer_div" />

      <div id="congrats" style="display: block">

        <p ref="congrats">Congratulations!</p>
            <v-particles id="particle" class="particles" ref="particles" />
      </div>
      <div id="final">
        <div class="end_logo" id="lexiscanLogo" ref="lexiscanLogo">
          <img src="~@/assets/images/icons/lexiscan-logo.svg" alt="lexiscan_graphic">               
        </div>
        <div class="white_fuzz" id="finalWhite" ref="finalW">      
        </div>
        <div id="syringe" ref="syringe">
          <img id="inject0" src="~@/assets/images/icons/injector.svg" alt="injection_graphic" :onClick="leaveExperience">
        </div>
        <div class="leave" :onClick="leaveExperience" id="leaveButton" ref="site">
          <p>Lexiscan.com ››</p>
        </div>    
        <div class="flexContainer" ref="finalcopy">
          <div id="endInfo">
            <p>Lexiscan is a registered trademark of Astellas US LLC.</p>
          <p style="padding: 8px 0px 8px 0px;"> Lexiscan was developed in collaboration with Gilead Palo Alto, Inc. (formerly CV Therapeutics, Inc.).</p>
           <p> ©2021 Astellas Pharma US, Inc. &nbsp;&nbsp;&nbsp; All rights reserved.<br>
            012-1619-PM &nbsp;&nbsp;&nbsp; 12/21</p> 
          </div>
        </div>
      </div>
    </div>
    
  </transition>
</template>

<script>
// @ is an alias to /src
//import router from "../router/index";
import { gsap } from "gsap";

import store from "../Store";
import Footer from "@/components/Footer";
import Particles from "@/components/Particles";
import {playVO, playSFX} from '@/lib/audio_clips.js';
export default {
  name: "Plane",
  components: {
  "v-footer": Footer,
  "v-particles": Particles,
  },
  data: function() {
    return {   
    };
  },
  mounted() {
    store.selected = store.state.home;
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      gsap.set(this.$refs.lexiscanLogo, {autoAlpha: 0});
      gsap.set(this.$refs.syringe, {autoAlpha: 0, y: 10});
      gsap.set(this.$refs.site, {autoAlpha: 0});
      gsap.set(this.$refs.finalcopy, {autoAlpha: 0});
      gsap.set(this.$refs.congrats, {scale:0});
      gsap.set(this.$refs.finalW, { autoAlpha: 0, scale: 0.4});

      gsap.to(this.$refs.finalW, { duration: .5, ease: "slow(0.1, 1, false)", scaleY: 1.8, scaleX: 1.6, autoAlpha: 0.65})
      gsap.to(this.$refs.congrats, 1, {scale: 1, ease: "back.out(3)", delay: .2});

      playSFX(1, null);
      setTimeout(() => { playVO(12, {callback: this.frameMissing}); }, 3000);     
      
      document.getElementById('resetButton').style.display = 'none';

      done();
    },
    leave(el, done) {
      done();
    },
    leaveExperience: function() {
      window.open("https://www.lexiscan.com/?utm_source=lexiscan_ar-experience&utm_medium=ar-experience&utm_campaign=fy21_astellas_branded_ar-experience&utm_content=lexiscan&utm_term=na","_self");
      // do we need to unmount things here? 
    },
    frameMissing: function() {
      playVO(13, {callback: this.proceed});
      var tl = gsap.timeline({});
      tl.to(this.$refs.congrats, {autoAlpha: 0, duration: .5}, "<");
      tl.to(this.$refs.particles, {autoAlpha: 0, duration: .5}, "<");
      tl.to(this.$refs.lexiscanLogo, {autoAlpha: 1, duration: 1}, "<");
      document.getElementById('particle').style.display = 'none';
    },
    proceed: function() {
      try {  
       playVO(14, {callback: this.startChallenge});
       var tl = gsap.timeline({});
      // tl.to(this.$refs.congrats, {autoAlpha: 0, duration: .5}, "<");
      // tl.to(this.$refs.finalW, {autoAlpha: 0, duration: .5}, "<");
      // tl.to(this.$refs.particles, {autoAlpha: 0, duration: .5}, "<");
      // tl.to(this.$refs.lexiscanLogo, {autoAlpha: 1, duration: 1}, "<");
       tl.to(this.$refs.site, {autoAlpha: 1, duration: 1, delay:3, onComplete:()=>{
         this.$refs.mainWindow.classList.add("blurbg")
       }});
      //  tl.set(this.$refs.mainWindow, {className:"Plane blurbg"});
       tl.to(this.$refs.syringe, {autoAlpha: 1, y: 0, duration: 1});
       tl.to(this.$refs.finalcopy, {autoAlpha: 1, duration: 1});
      //  setTimeout(() => { document.getElementById('congrats').style.display = 'none'; 
      //  document.getElementById('final').style.display = 'block'; }, 2000);
       } catch (e) {
         console.log("ERROR");
       }
    },
  },
};
</script>

<style lang="scss" scoped>
.Plane {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align:center;  
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.blurbg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.end_logo {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0%, -100%);
  -ms-transform: translate( 0%, -100%);
  z-index: 8;
}


#congrats {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -1px;
  padding:20px;
  // background-image: url("~@/assets/images/icons/confetti.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  z-index: 8;
  p {
    text-align: center;
    font-family: "HelveticaNeueCn";   
    font-weight: bold;
    font-size: 30pt;
    color: $purple;
    transform: translate(0%, 225px);
    -ms-transform: translate( 0%, 225px);
  }
}

#inject0{
  width: 100%;
  height: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //top: -5%;
  pointer-events: auto;
  transform: translate(5%, 0%);
  -ms-transform: translate(5%, 0%);
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
}

 #syringe{
  position: absolute;
  width: 90%;
  height: 75%;
  top: 54%;
  align-content: center;
  transform: rotate(32deg);
  z-index: 20;
 }

 .leave {
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 19;
  pointer-events: auto;
    
  p {
    width: 50%;
    margin: 0 auto;    
    color: white;
    padding: 21px 18px 18px 18px;
    font-family: "HelveticaNeueCn";
    font-style: normal;
    font-size: 20px;
    font-weight: bold;
   // letter-spacing: -0.5px;
    border-radius: 15px;
    background-color: $purple;
    animation: whitePulse 3s ease infinite;
  }
}

@keyframes whitePulse {
  from { -webkit-box-shadow: 0 0 0.1px #FFF; }
  50% {  -webkit-box-shadow: 0 0 18px #FFF; }
  to {-webkit-box-shadow: 0 0 9px #FFF; }
  }

.flexContainer {
  position: absolute;
  width: 100%;
  bottom: 13%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

 #endInfo {
  width: 90%;
  text-align: left;
  position: relative;
  margin: 0 auto;  
  background-color: rgba(255,  255, 255, 0.8);
  border-radius: 4px;
  padding: 15px 12px 12px 12px;
    
  p {
    width: 100%;
    margin: 0 auto;
    //line-height: 100%; 
    color: black;
    //padding: 12px;
    font-family: "HelveticaNeueMdCn";
    //font-weight: bold;
    font-size: 12px;
   // letter-spacing: -0.7px;
    //border-radius: 4px;
    

  }
}

#finalWhite{
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 20%;
  justify: center;
  align: center;
  top: 25%;
  z-index: 5;
  filter: blur(15px);
  left:0;
  right: 0;
  //transform: translate(0%, 65%);
  //-ms-transform: translate(0%, 65%);
  background-image: url("~@/assets/images/whitedot.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
}
</style>