<template>
  <router-view />
</template>

    

<script>
export default {
  created () {
      document.title = 'Astellas Experiences';
  }
}
</script>

<style lang="scss">
@import './scss/app.scss';
</style>
