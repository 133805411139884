<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane" id="tapContainer">
      <div class="cover" :onClick="setStage" ref="cover" />

      <div class="tap activate" :onClick="setStage" id="tap" ref="pop">
        <div id="tap_icon"></div>
        <div id="audio_icon"></div>
        <div id="move_icon"></div>
        <div id="greyBG"><br><h6>Let's get started!</h6><p>________</p><br><p>Turn your sound on.</p><p>________</p><br>
        <p>Find a surface and tap to place.</p><p>________</p><br><p>Move your phone around to see the objects from other angles.</p>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>

.Plane{
 // background-color: white;
}
.tap {
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 25%;


  p {
   // width: 85%;
    margin: 0 auto;
    color: $white;
   // padding-top:30px;
   // padding-left:40px;
    //padding-right:40px;
   // padding-bottom:15px;
    font-family: "HelveticaNeueCn";
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    //letter-spacing: -1px;
    //border-radius: 15px;
   // background-color: #00000090;

  }
}

#greyBG{
  width: 85%;
  position: relative;
  padding: 35px 25px 25px 35px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  background-color: #00000090;
}

 #tap_icon {
  width: 45pt;
  height: 45pt;
  z-index: 44;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  //padding: 18px;
  background-color: $purple;
  background-image: url("~@/assets/images/icons/tap-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  transform: translate(0%, 50%);
  -ms-transform: translate( 0%, 50%);
}

 #audio_icon {
  width: 45pt;
  height: 45pt;
  z-index: 44;
  position: fixed;
  left: 20%;
  border-radius: 50%;
  //padding: 18px;
  background-color: $purple;
  background-image: url("~@/assets/images/icons/audio-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  transform: translate(0%, -50%);
  -ms-transform: translate( 0%, -50%);
}

 #move_icon {
  width: 45pt;
  height: 45pt;
  z-index: 44;
  position: fixed;
  right: 20%;
  border-radius: 50%;
  //padding: 18px;
  background-color: $purple;
  background-image: url("~@/assets/images/icons/move-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  transform: translate(0%, -50%);
  -ms-transform: translate( 0%, -50%);
}

.letsgo {
  text-align: center;
  width: 100%;
  bottom: 0px;
  pointer-events: auto;
  padding: 0 0 2rem 0;
  filter: drop-shadow(0 10px 0.75rem rgba(0, 0, 0, 0.53));
}
.cover {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.activate {
  pointer-events: auto;
}
#countdown {
  position: absolute;
  text-align: center;
  top: 85px;
  width: 100%;
  font-size: 10rem;

  z-index: 2000;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";
import router from "../router/index";

import store from "../Store";
import { playCLickSound } from "@/lib/helper";
import {playVO} from '@/lib/audio_clips.js';

export default {
  name: "Plane",
  components: {},
  data: function() {
    return {
      instruct: new gsap.timeline({
        paused: false,
      }),
      shouldShow3dView: true,
      stageInit: false,
      timeRecenter: null,
      planeDetect: null,
      scene: document.getElementById("scene"),
    };
  },
  mounted() {
    this.planeDetect = document.getElementById("planeDetectObject");
    console.log("store.state   ", store.state);
    store.selected = store.state.intro;
    // Main Map

    let tl = gsap.timeline();
    gsap.set([this.$refs.pop, this.$refs.cover], { autoAlpha: 0 });
    //add 3 tweens that will play in direct succession.
    tl.to(this.$refs.pop, { delay: 2, duration: 1, autoAlpha: 1 });
    tl.to(this.$refs.cover, { duration: 1, autoAlpha: 1 }, "<");

    this.timeRecenter = setInterval(this.reCenter, 1000);

    setTimeout(() => { playVO(1); }, 2000);
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    next: function() {
      if(store.state.arReady){
        // set all sound
        playCLickSound();
        this.planeDetect.removeEventListener("model-loaded", this.animateIn);
      }
      //router.push("select");
    },
    reCenter: function() {
      if(store.state.arReady){
        // window.Geenee.activeSceneModel.startSlamExperience(window.innerWidth / 2, window.innerHeight-450);
      }
      console.log(store.state.arReady, "RECENTER");
    },
    setStage: function() {
      if(!store.state.arReady){
        return;
      }
      console.log("setStage");
      document.getElementById('tapContainer').style.visibility = 'hidden';
      clearInterval(this.timeRecenter);
      store.placedModel = true;
      window.Geenee.activeSceneModel.emitter.emit('digitas-final-placement')
      try {
        console.log(window.innerHeight);
        //turn this back on
        // window.Geenee.activeSceneModel.startSlamExperience(window.innerWidth / 2, window.innerHeight-200);
        router.push("intro");
        window.Geenee.activeSceneModel.emit('digitas-final-placement')
        // window.Geenee.activeSceneModel.startSlamExperience(0, 0);
      } catch (e) {
        console.log("ERROR");
      }
    },
  },
};
</script>
