<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">    
      <v-footer class="footer_div" />
      <div id="purpleBG">
        <v-wave ref="wave" />
      </div>
      <div id="purpleBG2">
        <img id="inject0" src="~@/assets/images/icons/injector.svg" alt="injection_graphic" ref="injector" />
      </div>
    <div id="intro1" ref="intro1">
      <div class="flexContainer">
        <div class="text_intro">
          <p><span style="line-height: 125%;">Symphony Health Solutions, NonRetailSource, January 2021, based on Astellas market definition 
            of pharmacologic stress agents, as used in SPECT MPI.</span><br><br>
            <span style="font-size: 12px; padding: 0px; line-height: 50%;">SPECT MPI = single-photon emission computed tomography myocardial perfusion imaging.</span></p>
        </div><br>
        <div class="next activate" :onClick="proceed" id="nextButton" :class="{'disabled': isStep1Disabled}">
          <p>Next ››</p>
        </div><br>
      </div>
      <div class="text_introTop" id="intro1b">
        <p>Lexiscan<sup>®</sup> (regadenoson) injection is the most widely used pharmacologic stress agent.</p>
      </div>
    </div>
    <div id="intro2" style="visibility:hidden;"  ref="intro2">
      <div class="flexContainer">
        <div class="text_intro">
          <p><span style="line-height: 125%;">Parenteral drug products should be inspected visually for particulate matter and discoloration 
            prior to administration, whenever solution and container permit. Do not administer Lexiscan<sup>®</sup> (regadenoson) 
            injection if it contains particulate matter or is discolored.</span></p>
        </div><br>
        <div id="extraInfo">
          <p><b>Reference: 1.</b> Lexiscan [package insert].<br />Northbrook, IL: Astellas Pharma US, Inc.</p>
        </div><br>
        <div class="next activate" :onClick="screen3" id="nextButton2" :class="{'disabled': isStep2Disabled}">
          <p>Next ››</p>
        </div><br>
      </div>
      <div class="text_introTop" id="intro2b">
        <p>It is the only standard-dose, ready-to-use, pharmacologic stress agent in a prefilled syringe.
          <sup>1</sup> </p>
      </div> 
    </div>    
    
    <div id="intro3" style="visibility:hidden" ref="intro3">
      <div class="intro__container"> 
      <div class="intro__containerTop" > 
      <!--  <img id="inject" src="~@/assets/images/icons/injector.svg" alt="injection_graphic">  -->     
        <div class="intro__header">
          <h7>1-Minute Administration Protocol</h7>
        </div>
        <img src="~@/assets/images/icons/minute.svg" alt="minute_protocol" style="width: 85%; height: 75%;">
      </div>
      <div style="padding: 5px"></div>  
      <div class="intro__containerBottom">
        <div class="intro__scroll">
          <h9>SELECT SAFETY INFORMATION</h9>
          <h4>Cardiac resuscitation equipment and trained staff should be available before administering Lexiscan. 
            Adhere to the recommended duration of injection. As noted in an animal study, longer injection times may 
            increase the duration and magnitude of increase in coronary blood flow.</h4>
          <table>
            <tr>
              <th class="intro__scroll1"><span class="super">a</span></th>
              <th class="intro__scroll1b">Patients should be instructed to
                avoid consumption of any products containing
                methylxanthines, including caffeinated coffee, tea or other
                caffeinated beverages, caffeine-containing drug products,
                aminophylline and theophylline for at least 12 hours before
                a scheduled radionuclide MPI.</th>
            </tr>
            <tr>
              <th class="intro__scroll1"><span class="super">b</span></th>
              <th class="intro__scroll1b">Parenteral drug products should be inspected visually for
          particulate matter and discoloration prior to
          administration, whenever solution and container permit. Do not
          administer Lexiscan if it contains particulate matter or is
          discolored.</th>
            </tr>
            <tr>
              <th class="intro__scroll1"><span class="super">c</span></th>
              <th class="intro__scroll1b">Flush after radionuclide administration per your lab
          protocol.</th>
            </tr>
          </table>

          <div style="padding-top: 5px; font-family: 'HelveticaNeueCn'; font-weight: normal;">Aminophylline is an adenosine antagonist, which may be used to 
          shorten the duration of increased coronary blood flow induced by Lexiscan. 
          Aminophylline has been used to terminate persistent pharmacodynamic effects. 
          Aminophylline may be administered in doses ranging from 50 mg to 250 mg by slow IV 
          injection (50 mg to 100 mg over 30-60 seconds). Methylxanthine use is not recommended 
          in patients who experience a seizure in association with Lexiscan administration.<sup>1</sup>
          </div>

          <div class="forTrigger" style="padding-top: 5px; font-family: 'HelveticaNeueCn'; font-weight: normal;"><b>References: 1.</b> Lexiscan [package insert].
                  Northbrook, IL: Astellas Pharma US, Inc.
                  <b>2.</b> Astellas. Lexiscan. Data on File.
          </div>
        </div>
        <div class="intro__arrow">_________________________________<p>Scroll <img id="down_icon" src="~@/assets/images/icons/down-icon.svg" alt="down_graphic"> </p>
        </div>
      </div>
      <div style="padding: 7px"></div>
      <div class="intro__CTA but" :onClick="challenge1" :class="{'disabled': isStep3Disabled}">
            Continue ››
      </div>
      <div style="padding: 5px"></div>
    </div>
    </div>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import router from "../router/index";
import Wave from "@/components/Wave";
import store from "../Store";
import Footer from "@/components/Footer";
import {playVO } from '@/lib/audio_clips.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "Plane",
  components: {
    "v-footer": Footer,
    "v-wave": Wave
  },
  data: function() {
    return {
      isStep1Disabled: true,
      isStep2Disabled: true,
      isStep3Disabled: true,
    };
  },
  mounted() {
    store.selected = store.state.home;
    this.screen1();
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });

      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      gsap.registerPlugin(ScrollTrigger);
      console.log('this.refs  ', this.refs)
      gsap.set(this.$refs.injector, {autoAlpha:0, y:10})
      gsap.set('.wavebg', {autoAlpha:0})
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      done();
    },
    screen1: function(){
      gsap.to(this.$refs.injector, {duration:1, autoAlpha:1, y: 0, delay:1, onComplete:() => {
        gsap.to(this.$refs.injector, {duration:1, y: 10, autoAlpha:1, delay:1, repeat:-1, yoyo: true, yoyoEase:true})
      }})
      
      gsap.to('.wavebg', {duration:1, autoAlpha:1, delay:.2})
      playVO(2, {callback: this.stepOneEnable});
    },
    stepOneEnable: function(){
      this.isStep1Disabled = false;
    },
    stepTwoEnable: function(){
      this.isStep2Disabled = false;
    },
    stepThreeEnable: function(){
      this.isStep3Disabled = false;
    },
    screen2: function(){
      console.log('>>>>>>>>>.this.$refs    ', this.$refs)
      gsap.to(this.$refs.intro1, {duration:1, autoAlpha:0})
      gsap.to(this.$refs.intro2, {duration:1, autoAlpha:1})
        // document.getElementById('nextButton').style.visibility = 'hidden';
        // document.getElementById('intro1').style.visibility = 'hidden';
        // document.getElementById('intro2').style.visibility = 'visible';
        // document.getElementById('inject0').style.visibility = 'visible';
        playVO(3, {callback: this.stepTwoEnable});//, 
    },
    screen3: function(){
      playVO(4, {callback: this.stepThreeEnable});//, 
      gsap.to(this.$refs.intro2, {duration:1, autoAlpha:0})
      gsap.to(this.$refs.intro3, {duration:1, autoAlpha:1, delay:1})
      gsap.to('.wavebg', {duration:.2, autoAlpha:0, overwrite:true})
      gsap.to(this.$refs.injector, {duration:.2, y: -117, autoAlpha:1, overwrite:true})
      
      gsap.to(".intro__arrow", {alpha: 0, scrollTrigger: {scroller: ".intro__scroll", trigger: ".forTrigger", start: "top bottom", end: "bottom bottom", scrub: true, markers: false, id: "scrub"}});
        // document.getElementById('intro2').style.visibility = 'hidden';
        // document.getElementById('intro3').style.visibility = 'visible';
        // document.getElementById('purpleBG').style.display = 'none';
    },
    next: function() {
      router.push("select");
    },
    proceed: function() {
      this.screen2();
    },
    challenge1: function() {
      console.log("challenge1");
      try {
        document.getElementById('intro3').style.visibility = 'hidden';
        router.push("challenge1");
      } catch (e) {
        console.log("ERROR");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Plane {
  position: absolute;
  width: 100%;
  height: 100%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events:none;
   -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.but {
  pointer-events:auto;
}
.popup {
  color: #fff;
  position: absolute;
  top: 10;
  /* right: 0px; */
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
}
.audiotxt {
  opacity: 1;
  display: none;
  width: 65%;
  margin: 0 auto;
  padding: 0.3rem 0.5rem;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  background-color: #cccccc;
  line-height: normal;
}
.footer_div {
  bottom: 0;
}
.next {
  width: 100%;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 20;   
  pointer-events:auto;
  vertical-align: middle;
  p {
    width: 30%;
    position: relative;
    margin: 0 auto;    
    color: white;
    vertical-align: baseline;
    padding: 18px 15px 15px 15px;
    font-family: "HelveticaNeueCn";
    font-style: normal;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
   // letter-spacing: -0.5px;
    border-radius: 15px;
    background-color: $purple;
  }
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events:none;
}
.text_intro {
  width: 100%;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 5;

  p {
    width: 90%;
    margin: 0 auto;
   // line-height: 125%;   
    color: $white;
    padding: 18px 15px 15px 15px;
    font-family: "HelveticaNeueCn";
   // font-weight: bold;
    font-style: normal;
    font-size: 14px;
   // letter-spacing: -0.5px;
    border-radius: 15px;
    background-color: #00000080;
  }
}

.text_introTop {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 12%;
  z-index: 5;
    
  p {
    width: 80%;
    margin: 0 auto;
    line-height: 115%;   
    color: $black;
    padding: 18px 15px 15px 15px;
    font-family: "HelveticaNeueCn";
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.7px;
    border-radius: 10px;
    background-color: rgba(255,  255, 255, 0.8);

  }
}

.flexContainer {
  position: absolute;
  width: 100%;
  bottom: 13%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro{
    &__container{
      position: absolute;
      width: 100%;
      height: 90%;
      top: 8%;
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
 
    &__containerTop {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    align-items: center;
    //top: 20%;
    width: 85%;
    height: 35%;
    background-color: white;
    border-radius: 10px;
    pointer-events:auto;
    }

   &__containerBottom {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    align-items: center;
    width: 85%;
    height: 25%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    pointer-events:auto;
    }


  &__header {
    color: $purple;
    font-weight: $weight-bold;
    font-size: $size-med;
    width: 100%;
    z-index: 7;
    text-align: center;
    padding-top: 24px;
    flex-shrink: 0;
    }

  &__scroll {
    align-items: top;
    text-align: left;
    overflow-y:auto;
    padding: 5px;
    flex-grow: 1;
    top:0;
    pointer-events:auto;
    width: 95%;
    z-index: 17;
    sup {
      font-size: 8px;
        }
    }

  &__scroll1 {
    align-items: left;
    padding-right: 1.5px;
    //top:0;
    z-index: 17;
    vertical-align: super;
    font-family: "HelveticaNeueCn";
    font-weight: normal;
    font-size: 12px;
    }

  &__scroll1b {
    z-index: 17;
    font-family: "HelveticaNeueCn";
    font-weight: normal;
    font-size: 14px;
    padding: 8px 0px 8px 0px;   
    }

  &__arrow {
    font-family: "HelveticaNeueCn";
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    line-height: 0;

    p{
      text-align: right;
      justify-content: right;
      padding-right: 30px;
      transform: translate(0%, 30%);
      -ms-transform: translate(0%, 30%);
    }
    }

  &__CTA {
    align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    padding: 18px 15px 15px 15px;
    //bottom: 0;
    border-radius: 10px;
    background-color: $purple;
    color: white;
    text-align: center;
    font-family: "HelveticaNeueCn";
    font-size: 20px;
    font-weight: bold;
   // letter-spacing: -0.6px;
    z-index: 15;
  }

  }

  // .intro__scroll::-webkit-scrollbar {
  // width: 15px;
  // color: $purple;
  // z-index: 15;
  // }

  // .intro__scroll::-webkit-scrollbar-track {
  // background-color: $purple;
  // border: 5px solid transparent;
  // background-clip: content-box;
  // z-index: 15;
  // }

  // .intro__scroll::-webkit-scrollbar-thumb {
  //   background-color: $purple;
  //   border-radius: 15px;
  //   z-index: 15; 
  // }

#intro3{
  position: absolute;
  height:95%;
  width: 100%;
  align-items: center;
  //top: 5%;
  z-index: 23;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#inject{
  width: 130%;
  height: auto;
  position: absolute;

  transform: translate(12%, -55%);
  -ms-transform: translate(12%, -55%);
  z-index: 19;
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
}

#inject0{
  width: 280px;
  height: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: -7%;
  transform: translate(5%, 0%);
  -ms-transform: translate(5%, 0%);
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5));
}

.super{
 vertical-align: super;
 font-size: 12px;
 }

 #purpleBG{
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-image: url("~@/assets/images/icons/purple-trail.svg");
  background-size: 400%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
    transform: translate(0%, -15%);
  -ms-transform: translate(0%, -15%);
  //background-origin: content-box;
 }

  #purpleBG2{
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 400%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 35;
 }

 #extraInfo {
  width: 100%;
  text-align: center;
  position: relative;
    
  p {
    width: 90%;
    margin: 0 auto;
    //line-height: 100%;   
    color: $black;
    padding: 14px;
    font-family: "HelveticaNeueCn";
    //font-weight: bold;
    font-size: 14px;
    //letter-spacing: -0.7px;
    border-radius: 4px;
    background-color: rgba(255,  255, 255, 0.8);

    }
  }

 #down_icon {
  width: 12pt;
  height: 12pt;
  transform: translate(0%, 25%);
  -ms-transform: translate(0%, 25%);
}

sup {
  vertical-align: baseline;
  position: relative;
  top: -0.35em;
  font-size: 12px;
}

</style>