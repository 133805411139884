<template>
  <div class="webAr pagefull">
    <v-orientation v-if="landscape" />
    <v-loading class="loading" v-if="!loaded" />
    <v-header />
    <router-view class="popover" v-if="loaded" @yourEvent="onYourEvent"></router-view>
     <geenee-magellan></geenee-magellan>

     
    <!--<v-footer class="footer" /> -->
  </div>
</template>
<style lang="scss">
#hudMain {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  margin: 10px auto;
}
.recorder-container {
  // background-color: red;
}
.playbut {
  width: 115%;
  height: 50px;
}
.footer_div {
  visibility: hidden;
  bottom: -120%;
}
.popover {
  width: 100%;

  z-index: 999;
  margin: 0 auto;
  height: 100%;
  // background-color: rebeccapurple;
  // display: flex;
  position: relative;
  position: absolute;
  bottom: 0;
  pointer-events: none;
}
</style>
<script>

import store from "../Store";
import { setAllObjectParams } from "@/lib/helper";
// import { BlendFunction } from 'postprocessing'
// import * as THREE from "three";
const baseConfig = [
{
    id: 1,
    scene_experience_type: 'slam',

    projectId: 11,

    arModel: {

        url: '/med_test_noEmission.glb',
        id: 1,
        body: {
            model: {
            url: '/med_test_noEmission.glb',
            scale: {
                x: 0.07,
                y: 0.07,
                z: 0.07
            },
            translate: {
                x: 0,
                y: 0,
                z: 0,
              },
              position: {
                x: 0,
                y: 0,
                z: -0.5
              }
            }
        }
    },
    disableDefaultCanvasClick: true,
    visible: false,
    customUI: {
        instructions:        true,
        motionPermission:    false,
        loader:              false,
        snapshot:            true,
        snapTimer:           true,
        videoPermission:     false,
        fallbackPlaceholder: {
            ifDesktop:          true,
            unsupportedBrowser: true,
            goPortrait:         true,
            geeneeLogo:         true
        },
    },

    onSceneInit: (gl) => {
      console.log("init", gl);
    },
    onReady: (canvasContext) => {
      
        document.getElementById('geenee-app').addEventListener(
            'touchmove',
            (event) => {
                if (event.scale !== 1) {
                    event.preventDefault()
                }
            },
            { passive: false }
        )
    
    

      console.log('canvasContext    ');
      if (canvasContext && canvasContext.scene) {

          const { scene } = canvasContext;
          window.setOutlineProps({
                // hiddenEdgeColor: '#8f4eac',
                // visibleEdgeColor: '#8f4eac',
                edgeStrength: 10.0,
                pulseSpeed: 0.5,
                visibleEdgeColor: 0x652D89,
                hiddenEdgeColor: 0x652D89,
                height: 480,	// Also influences edge thickness
                blur: true,	// Enable blurring
                blendFunction: 2
            });
          let interval = null;
            window.Geenee.activeSceneModel.emitter.addListener('geenee-assets-loaded', () => {
              store.modelLoaded = true;
              store.arReady = true;
              window.scene = scene;
              window.canvasContext = canvasContext;
              if(interval !== null){
                clearInterval(interval);
              }
              
                  interval = setInterval(() => {
                  console.log('RUNNNINGINGNIGNINIGNIGINGNINIGINGNIGINNIIN')
                  window.Geenee.activeSceneModel.startSlamExperience(window.innerWidth / 2, window.innerHeight - 200);
                }, 1500);
                setAllObjectParams('baseitem');
              
              
            })


            window.Geenee.activeSceneModel.emitter.addListener('digitas-final-placement', () => {
              console.log('FINAl PLACE<ENT')
              clearInterval(interval);

              // window.Geenee.activeSceneModel.startSlamExperience(window.innerWidth / 2, window.innerHeight - 200);
            })

      }
    },
    onRender: () => {
      // console.log('render', gl);
    },
  },
];
window.magellanConfig = baseConfig;










import Orientation from "@/components/Orientation";

// import Footer from "@/components/Footer";
import Header from "@/components/Header";

import Loading from "@/components/Loading";
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    "v-orientation": Orientation,
    "v-loading": Loading,
    "v-header": Header,
  },
  data: function() {
    return {
      showStats: false,
      shouldShow3dView: true,
      idx: 0,
      landscape: false,
      maps: null,
      animationList: ["Intro", "a"],
      mainMap: false,
      loaded: store.state.modelLoaded,

    };
  },
  methods: {
    showModel: function() {
      console.log("showModel  ");
      // this.playAnimation('maps', 1)
    },
    setAnimation: function() {
      this.maps.removeAttribute("gltf-model");
      this.maps.setAttribute("gltf-model", "./assets/models/welcome.glb");
      this.maps.setAttribute("animation-mixer", { timeScale: 0 });
      console.log("Set Animation", this.maps);
    },
    playAnimation: function(name) {
      console.log("Play Animation", name);
      this[name].setAttribute("animation-mixer", {
        clip: this.animationList[0],
        loop: "repeat",
        crossFadeDuration: 0.4,
      });
    },
    onYourEvent(yourDataIfYouHaveAny) {

      console.log('yourDataIfYouHaveAny', yourDataIfYouHaveAny)
    },
    playSound: function(name, action, speed = null) {
      this[name].setAttribute("sound", {
        getPlaybackRate: speed ? speed : this.state.slowmo,
        volume: 1,
      });

      if (action === "play") {
        this[name].components.sound.playSound();
      } else if (action === "stop") {
        this[name].components.sound.playStop();
      } else if (action === "pause") {
        this[name].components.sound.pauseStop();
      }
    },
    finishAnimation: function() {
      console.log("FINISHED ANIMATION");
    },
    arLoaded: function(){
      console.log("FINISHED AR LOAD");
    },
    orientationChange: function() {
      if (window.innerWidth > window.innerHeight) {
        this.landscape = true;
        store.landscape = true;
        console.log("landscape True");
      } else {
        console.log("landscape FALSE");
        this.landscape = false;
        store.landscape = false;
      }
    },
  },
  mounted: function() {
    let loadedChecker = setInterval(()=>{

      if(store.state.modelLoaded){
        clearInterval(loadedChecker);
        this.loaded = store.state.modelLoaded;
      }
    })
    // this.maps = document.getElementById("modelall");
    window.addEventListener("resize", this.orientationChange);
    // window.addEventListener("geenee-app-started", this.arLoaded);
    this.showModel();
  },
  unmounted: function() {
    window.removeEventListener("resize", this.orientationChange);
  },
};
</script>
