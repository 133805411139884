<template>
  <div class="error">
    <div class="error___text">
    An error occurred while
      processing your request.<br>
      Sorry for the inconvenience.<br>
      Please return to the
     <h8 :onClick="gotoWelcome"> welcome page</h8>.
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  mounted() {
    // Run Code once code is mounted
  },
  methods: {
    reload() {
      // Go Back to main Page
    },
    gotoWelcome() {
     // window.open("","_self");
      console.log("go to welcome page");
      let url = `https://${window.location.hostname}${window.location.port != '' ? ":" + window.location.port : ''}?${Date.now()}`;

      console.log('url   ', url);
      window.open(url, "_self");
    },
  },
};
</script>

<style lang="scss" scoped>

.error {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;  

    &___text{
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    font-family: "HelveticaNeueMdCn";
   // font-weight: 550;
    font-style: normal;
    color: $grey49;
    font-size: 24px;
   // letter-spacing: -0.6px;
    line-height: 120%;
    }
  }

</style>
