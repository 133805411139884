<template>
  <div class="Loading">
    <div class="Loading__container">
      <img
        class="Loading__spin rotating"
        :src="require('@/assets/images/loading.png')"
        alt="Loading"
      />
      <div class="copy"><p>Loading...</p></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data: function() {
    return {
      scene: null,
    };
  },
  methods: {},
  mounted: function() {},
};
</script>
<style scoped lang="scss">
.Loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;
  &__container {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 60%;
    }
    .copy {
      color: #642C88;
      font-family: "HelveticaNeueCn";
      font-weight: bold;
     // background-color: #959595;
      padding: 6px;
      margin-top: 10px;
      border-radius: 5px;
      font-size: 25px;
      width: 80%;
      margin: 10px auto 0 auto;
    }
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}
</style>
