<template>
  <transition>
    <div class="Plane"> 
      <div class="forwarder"  id="code">
        <div></div>
        <div id="greyBG"><br><p>Sorry, you need to use your
            smartphone to enter. Please scan the QR code
            using your smartphone.</p>
           <div id="qrHolderstyle">
             <img id="qrCode" src="~@/assets/images/Staging_QR_code.png" alt="qr_code"></div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>

.Plane{
  width: 100%;
  height: 100%;
  background-color: white;
}
.forwarder {
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 20%;
     
  p {
    //margin: 0 auto;    
    color: white;
    font-family: "HelveticaNeueCn";
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
}

#greyBG{
  width: 80%;
  position: relative;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.65);
}

 #qrCode {
  width: 175pt;
  height: 175pt;
  z-index: 44;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}


</style>
<script>
//import router from "../router/index";
//import store from "../Store";

export default {
  name: "Desktop",
  components: {},
  data: function() {
    return {

    };
  },
  mounted() {
  //  console.log("store.state   ", store.state);
   // store.selected = store.state.intro;
  },
  updated() {

  },
  methods: {

  },
};
</script>
