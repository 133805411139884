<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">
      <v-footer class="footer_div" />
      <div id="hub" ref="hub">
        <p id="score">{{ score }}</p>
        <p id="instructions">{{ instructions }}</p>
      </div>
      <div id="menuContainer" ref="menuContainer">
        
        <div class="challenge_intro"  id="introC" ref="introC">      
          <img :src="getURL(challengeItem.main)" alt="challenge_graphic" />
        </div>
        <div class="challenge_intro" id="introWhite" ref="introW">      
          <!--<img :src="getURL(challengeItem.white)" alt="challenge_white" /> -->
        </div>
        
        <div id="directions" ref="directions">
          <div id="round_icon" :style="{ backgroundImage: `url(${getURL(challengeItem.icon)})` }"></div>
          <div class="bg">
            <p style="color: red; font-size: 50px;">NO</p>
            <p style="font-size: 25px;" v-html="challengeItem.iconCopy"></p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import * as THREE from "three";

// // import { WebGLRenderer } from 'three';
// import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
// import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
// import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
// @ is an alias to /src
import router from "../router/index";

import {playVO, playSFX} from '@/lib/audio_clips.js';
import { gsap } from "gsap";
import store from "../Store";
import Footer from "@/components/Footer";
import { addOutline } from "@/lib/helper";
let itemSelected = null;
const objectList = [
  "y_port",
  "quirur_table",
  "battery_grp1",
  "needle1_grp",
  "needle1_grp1",
  "needle2_grp",
  "bottle_grp",
  "weight_grp",
  "bag_grp",
  "calculator_grp",
  "Lexiscan_syringe_grp",
  "infusion_grp",
];
function checkName(item) {
  // console.log(item)
  let exist = objectList.indexOf(item.name);
  // let itemName = item;
  // console.log(item.name)
  // console.log('exist  >>>>>>', exist)
  if (item.name === "baseitem" || item.name === "quirur_table") {
    itemSelected = null;
    return false;
  } else if (exist === -1) {
    checkName(item.parent);
  } else {
    itemSelected = item;
    return true;
  }
}
export default {
  name: "Plane",
  components: {
    "v-footer": Footer,
  },
  data: function() {
    return {
      canSelect: false,
      scene: document.getElementById("scene"),
      sceneEl: document.querySelector("a-scene"),
      box: null,
      selectedArr: [],
      lastSelected: null,
      instructions: '',
      wrongText: ["Incorrect! Try again.", "Incorrect! Here is a hint. Try again."],
      challenges: [
        {
          id: 0,
          matchArr: ["calculator_grp", "weight_grp"],
          main: "images/icons/challenge.svg",
          icon: "images/icons/scale-icon.svg",
          vo1: 6,
          vo2: 7,
          copy: ['Select the first of the 2 items.', 'Correct! Select the second item.', "Correct! Great job! Let's head to the next challenge."],
          iconCopy: 'weight-based dosing calculations'
        },
        {
          id: 1,
          matchArr: ["bottle_grp"],
          main: "images/icons/challenge.svg",
          icon: "images/icons/bottle-icon.svg",
          vo1: 8,
          vo2: 9,
          copy: ['Select the item.', "Correct! Great job! Let's head to the next challenge."],
          iconCopy: 'drawing up dose from vial'
        },
        {
          id: 2,
          matchArr: ["battery_grp1", "infusion_grp"],
          main: "images/icons/challenge.svg",
          icon: "images/icons/monitor-icon.svg",
          vo1: 10,
          vo2: 7,
          copy: ['Select the first of the 2 items.', 'Correct! Select the second item.', "Correct! Great job! Let's head to the next challenge."],
          iconCopy: 'infusion pump or replacement batteries'
        },
        {
          id: 3,
          matchArr: ["bag_grp"],
          main: "images/icons/challenge.svg",
          icon: "images/icons/iv-icon.svg",
          vo1: 11,
          vo2: 9,
          copy: ['Select the item.', "Correct! Great job!"],
          iconCopy: 'special IV sets'
        },
      ],
      challengeInit: false,
      wrongTimes: 0,
      challenge: 0,
      score: '',
      challengeItem: {
        id: 0,
        matchArr: ["calculator_grp", "weight_grp"],
        main: "images/icons/challenge.svg",
        icon: "images/icons/scale-icon.svg",
        white: "images/whitedot.svg",
        vo1: 6,
        vo2: 7,
        copy: ['Select the first of the 2 items.', 'Correct! Select the second item.', "Correct! Great job! Let's head to the next challenge."],
        iconCopy: 'weight-based dosing calculations'
      },
      composer: null,
    };
  },
  mounted() {
    store.selected = store.state.home;
    // playVO(5, {callback: this.startChallenge});
    // this.startChallenge();
    this.initGeenee();
    this.effectsPass();
    playSFX(3, {callback: this.initVO});
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
    checkSelected() {
      if(!this.challengeInit) return;
      if (this.challengeItem.matchArr.filter((e) => e === itemSelected.name).length > 0) 
      {
        itemSelected.visible = false;
        this.selectedArr.push(itemSelected);
        console.log('MATCH COUNT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.selectedArr.length, this.challengeItem.matchArr.length);
        if (this.selectedArr.length >= this.challengeItem.matchArr.length) {
          console.log("FINISHED >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  ", this.challenge);
          this.score = `${this.selectedArr.length} of ${this.challengeItem.matchArr.length} Selected Correctly`;
          this.instructions = this.challengeItem.copy[this.selectedArr.length];
          this.challengeInit = false;
          playSFX(0, {callback: this.sfxPressed});
          this.next();
        } else {
          console.log('called checker>>>>>>>>>')
          this.instructions = this.challengeItem.copy[this.selectedArr.length];
          this.score = `${this.selectedArr.length} of ${this.challengeItem.matchArr.length} Selected Correctly`;
          playSFX(2, {callback: this.sfxPressed});
        }
      } else {
        playSFX(5, {callback: this.sfxPressed});
        let objRotOG = itemSelected.rotation.z;
        gsap.fromTo(itemSelected.rotation, {z:itemSelected.rotation.z-.1}, {z: itemSelected.rotation.z, duration:.1, overwrite:true, repeat:5, yoyo:true, onComplete:function(){
          gsap.to(itemSelected.rotation, {z: objRotOG, duration:.2})
        }});
        
        this.instructions = this.wrongText[this.wrongTimes];
        this.wrongTimes += 1;
        if(this.wrongTimes > 1){
          this.wrongTimes = 0;
          this.highlightItems();
        }
      }
    },
    highlightItems(){
      let highLightArray = [];
      this.challengeItem.matchArr.forEach((objName) => {
        highLightArray.push(window.scene.getObjectByName(objName));
      })

      setTimeout(() => {
        // highLightArray.forEach(element => {
        //   let objRotOG = element.scale.z;
        //   gsap.fromTo(element.scale, {z:element.scale.z-.1}, {z: element.scale.z, duration:.1, overwrite:true, repeat:5, yoyo:true, onComplete:function(){
        //     gsap.to(element.scale, {z: objRotOG, scale:.2})
        //   }});
        // });

        addOutline(highLightArray);
      }, 200);
      setTimeout(() => {
        window.outlineObjects([]);
        addOutline(this.selectedArr);
      }, 3000);
    },
    effectsPass(){
      console.log('ADDING EFFECTS');
    },
    initGeenee() {


      window.Geenee.activeSceneModel.emitter.addListener(
        "on-model-click",
        (intersections) => {
          console.log("Model clicked", intersections);
          if (intersections.length > 0) {
            console.log(intersections[0]);
            let model = intersections[0];
            
            let itemName = checkName(model.object);
            console.log(itemName);
            if (
              (this.lastSelected &&
                this.lastSelected.name === itemSelected.name) ||
              this.selectedArr.filter((e) => e.name === itemSelected.name)
                .length > 0
            ) {
              return;
            }
            this.lastSelected = itemSelected;
            store.selectedobject = itemSelected;
            window.selectedItem = itemSelected;
            setTimeout(() => {
              addOutline(this.selectedArr);
            }, 200);
            
            window.selectedArr = this.selectedArr;
            this.checkSelected();
          }
          // Geenee.activeSceneModel.stopExp()
        }
      );
    },
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);

    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      gsap.set(this.$refs.hub, { autoAlpha: 0, overwrite: true });
      gsap.set(this.$refs.directions, { autoAlpha: 0, overwrite: true });
      gsap.set(this.$refs.introC, { autoAlpha: 0, scale: 0.4, overwrite: true });
      //gsap.to(this.$refs.introC, { duration: 2.5, ease: "back.out(3)", scale: 1, autoAlpha: 1 });
      gsap.set(this.$refs.introW, { autoAlpha: 0, scale: 0.4, overwrite: true });
      gsap.to(this.$refs.introW, { duration: .5, ease: "slow(0.1, 1, false)", scaleY: 2.1, scaleX: 1.8, autoAlpha: 0.8});
      gsap.to(this.$refs.introC, { duration: 1, ease: "back.out(3)", scale: 1, autoAlpha: 1, delay: .2 })
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      done();
    },
    instructionSteps: function(){
      playVO(this.challengeItem.vo2);
      gsap.to(this.$refs.directions, { duration: 0.5, autoAlpha: 0, overwrite: true });
      gsap.to(this.$refs.hub, { duration: 0.5, autoAlpha: 1, overwrite: true });
      this.challengeInit = true;
    },
    startChallenge:function(){
      // document.getElementById("hub").style.display = "none";
      
      this.lastSelected = null;
      this.instructions = '';
      this.wrongTimes = 0;
      this.score = '';
      this.challengeItem = this.challenges[this.challenge];
      this.score = `0 of ${this.challengeItem.matchArr.length} Selected Correctly`;
      this.instructions = this.challengeItem.copy[0];
      gsap.to(this.$refs.hub, { duration: 0.2, autoAlpha: 0, overwrite: true });
      setTimeout(() => {
        this.selectedArr.forEach(element => {
          element.visible = false;
        });
        this.selectedArr = [];
        playVO(this.challengeItem.vo1, {callback: this.instructionSteps});
        gsap.to(this.$refs.introC, { duration: 0.2, autoAlpha: 0, overwrite: true });
        gsap.to(this.$refs.introW, { duration: 0.2, autoAlpha: 0, overwrite: true });
        //document.getElementById("introWhite").style.display = 'none';
        gsap.to(this.$refs.directions, { duration: 0.2, autoAlpha: 1, overwrite: true });
      }, 2000);
     
    },
    next: function() {
      this.challenge++;
      console.log("leave>>>>>", this.challenge, this.challenges.length-1);
      if(this.challenge <= this.challenges.length-1){
        console.log("NEXT IS >>>>>> ", this.challenges[this.challenge]);
        setTimeout(() => {
          this.startChallenge();
        }, 2000);
      } else {
        console.log("NEXT IS END>>>>>> ");
        setTimeout(() => {
          this.selectedArr.forEach(element => {element.visible = false; });
          this.selectedArr = [];
          router.push("final");
        }, 3000);
      }

    },
    sfxPressed: function(){
      console.log("Sfx pressed");
    },
    initVO: function(){
      playVO(5, {callback: this.startChallenge});
    },
  },
};
</script>

<style lang="scss" scoped>
.Plane {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  pointer-events: none;
}

.challenge_intro {
  width: 90%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
 // top: 40%;
 // transform: translate(0%, 50%);
 // -ms-transform: translate(0%, 50%);
}

#introC{
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  z-index: 15;
}

#introWhite{
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 20%;
  justify: center;
  align: center;
  top: 37%;
  z-index: 5;
  filter: blur(15px);
  left:0;
  right: 0;
  //transform: translate(0%, 65%);
  //-ms-transform: translate(0%, 65%);
  background-image: url("~@/assets/images/whitedot.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
}

#menuContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
//  transform: translate(0%, -80%);
//  -ms-transform: translate(0%, -80%);
}

#round_icon {
  width: 50vw;
  height: 50vw;
  z-index: 12;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  //padding: 18px;
  background-color: $purple;
  background-image: url("~@/assets/images/icons/scale-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
 // transform: translate(0%, -60%);
  //-ms-transform: translate(0%, -60%);
}
#directions {
  width: 100%;
  text-align: center;
  position: relative;
 // top: 20%;
  margin-left: auto;
  margin-right: auto;

  align-items: center;
  justify-items: center;
  //transform: translate(0%, -5%);
  //-ms-transform: translate(0%, -5%);
  //letter-spacing: -1px;
  padding: 20px;

  p {
    font-family: "HelveticaNeueCn";
    font-weight: bold;
    color: $grey;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.bg {
  width: 75%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
 // top:20%;
  padding: 40px 20px 20px 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0%, -16%);
  -ms-transform: translate(0%, -16%);
}


#hub {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 12%;
  z-index: 3;
}

#score {
 // position: fixed;
  color: $white;
  font-family: "HelveticaNeueCn";
  font-weight: bold;
  font-size: 14pt;
  text-shadow: 0px 2px 7px #000000;
  z-index: 6;
}

#instructions {
 // position: fixed;
  width: 65%;
  margin: 0 auto;
  color: $white;
  padding: 23px 20px 20px 20px;
  font-family: "HelveticaNeueCn";
  font-weight: bold;
  font-size: 18px;
  border-radius: 15px;
  background-color: #00000080;
  z-index: 6;
}
</style>
